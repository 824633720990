<template>
  <div>
    <loader-hummingbird :delay="1000" v-if="loading"></loader-hummingbird>
    <div v-else-if="!subscription" class="container">Subscription not found</div>
    <div v-else class="has-background-white-light">
      <section class="section">
        <div
          class="container narrow-container has-light-shadow has-background-white has-large-padding"
        >
          <h1 class="is-size-3 has-text-centered has-text-grey-dark">Subscription Details</h1>
          <div class="divider"></div>
          <div class="has-margin-top">
            <p class="is-overline">Status</p>
            <subscription-status :subscription="subscription"></subscription-status>
          </div>
          <div class="has-margin-top">
            <p class="is-overline">Service</p>
            <p>
              {{ subscription.plan.service.title}}
              <br />
              <router-link
                class="is-size-7"
                :to="`/service/${subscription.plan.service.id}`"
              >View Service</router-link>
            </p>
          </div>
          <div class="has-margin-top">
            <p class="is-overline">Plan</p>
            <p>{{ subscription.plan.name }}</p>
          </div>
          <div class="has-margin-top">
            <p class="is-overline">Amount</p>
            <p>{{ subscription.plan.amountFormatted }}</p>
          </div>
          <div class="has-margin-top">
            <p class="is-overline">Next Billing Date</p>
            <p>{{ nextBillDateDisplay }}</p>
          </div>
          <div class="has-margin-top">
            <p class="is-overline" v-if="subscription.canCancel">Actions</p>
            <el-button
              v-if="subscription.canCancel"
              @click="cancelSubscription"
              :loading="cancelLoading"
              size="small"
              type="primary"
              plain
              class="has-margin-top"
            >Cancel Subscription</el-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify'
import { timeFormat } from 'd3'
import { getStripeSubscription, cancelStripeSubscriptionAtPeriodEnd } from '@/api/stripe.service'
import Subscription from '@/models/Subscription'
import LoaderHummingbird from '@/components/LoaderHummingbird.vue'
import SubscriptionStatus from '@/components/SubscriptionStatus.vue'

const dateTimeFormat = timeFormat('%_d %b %Y')
const getSubscription = `query GetSubscription($id: ID!) {
  getSubscription(id: $id) {
    id
    stripeId
    owner
    plan {
      id
      stripeId
      name
      owner
      allowance
      service {
        title
        id
      }
    }
    createdAt
  }
}
`

export default {
  name: 'Subscription',
  components: {
    LoaderHummingbird,
    SubscriptionStatus,
  },
  data() {
    return {
      id: this.$route.params.id,
      user: this.$store.state.user,
      subscription: null,
      loading: true,
      cancelLoading: false,
    }
  },
  async created() {
    this.subscription = await this.fetchSubscription()
    this.loading = false
  },
  computed: {
    nextBillDateDisplay() {
      if (this.subscription.nextChargeDate) {
        return dateTimeFormat(this.subscription.nextChargeDate)
      }
      return '---'
    },
  },
  methods: {
    async fetchSubscription() {
      try {
        const res = await API.graphql(graphqlOperation(getSubscription, { id: this.id }))
        if (res.data.getSubscription) {
          this.stripeId = res.data.getSubscription.stripeId
          const { data: stripeSub } = await getStripeSubscription(this.id)
          if (stripeSub) {
            return new Subscription(res.data.getSubscription, stripeSub)
          }
        }
        return null
      } catch (e) {
        return null
      }
    },
    async cancelSubscription() {
      try {
        this.cancelLoading = true
        await cancelStripeSubscriptionAtPeriodEnd(this.id)
        this.subscription = await this.fetchSubscription()
        this.cancelLoading = false
      } catch (e) {
        this.$notify.error({
          title: 'Oh no!',
          message: "Something went wrong. Please contact our support team directly and we'll get it figured out",
          duration: 0,
        })
      }
    },
  },
  filters: {
    formatDate(value) {
      return dateTimeFormat(value)
    },
  },
}
</script>

<style lang="scss" scoped>
.has-background-white-light {
  background-image: linear-gradient(180deg, #fff, #fff 30%, #e3ddf86e 30%, #e3ddf82e 100%);
}
</style>
